<script setup>
import UserActions from "@/components/UserActions.vue";

defineProps({
  tableId: {
    type: String,
    required: true,
  },
  headers: {
    type: Array,
    required: true,
  },
  columns: {
    type: Array,
    required: true,
  },
  entries: {
    type: Array,
    required: true,
  },
  footers: {
    type: Array,
    required: true,
  },
  canEdit: {
    type: Boolean,
    required: true,
  },
  canDelete: {
    type: Boolean,
    required: true,
  },
  externalDeleteFunc: {
    type: Function,
    required: false,
    default: () =>
      Promise.resolve({
        status: true,
        message: "Successful entry deletion",
        data: {},
      }),
  },
  deletePromptParams: {
    type: Array,
    required: false,
    default: () => [],
  },
  externalEditFunc: {
    type: Function,
    required: false,
    default: () =>
      Promise.resolve({
        status: true,
        message: "Successful entry modification",
        data: {},
      }),
  },
});
</script>

<template>
  <div v-if="entries.length" class="paginated-table-options">
    <div>
      <label
        >Rows
        <select v-model="numPerPage">
          <option v-for="i in allNumPerPage" :key="i" :value="i">
            {{ i }}
          </option>
        </select>
      </label>
    </div>
    <div>
      <button @click.prevent="toFirstPage">&laquo;</button
      ><button @click.prevent="toPrevPage">&lsaquo;</button>
      <p>{{ optionsFrom }}-{{ optionsTo }} of {{ optionsOf }}</p>
      <button @click.prevent="toNextPage">&rsaquo;</button
      ><button @click.prevent="toLastPage">&raquo;</button>
    </div>
  </div>
  <div v-if="entries.length" class="table-holder">
    <table :id="tableId">
      <thead>
        <tr>
          <th>#</th>
          <th v-for="header in headers" :key="header">{{ header }}</th>
          <th v-if="canEdit || canDelete">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, index) in pagedData" :key="index">
          <td>{{ calcRowIndex(index + 1) }}</td>
          <td v-for="col in columns" :key="col">{{ entry[col] }}</td>
          <td v-if="canEdit || canDelete">
            <UserActions
              :can-delete="canDelete"
              :can-edit="canEdit"
              @delete="internalDelete(entry)"
              @edit="internalEdit(entry)"
            ></UserActions>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="shouldShowFooter">
        <tr>
          <td></td>
          <td v-for="col in footers" :key="col">{{ col }}</td>
          <td v-if="canEdit || canDelete"></td>
        </tr>
      </tfoot>
    </table>
  </div>
  <div v-if="entries.length" class="paginated-table-options">
    <div>
      <label
        >Rows
        <select v-model="numPerPage">
          <option v-for="i in allNumPerPage" :key="i" :value="i">
            {{ i }}
          </option>
        </select>
      </label>
    </div>
    <div>
      <button @click.prevent="toFirstPage">&laquo;</button
      ><button @click.prevent="toPrevPage">&lsaquo;</button>
      <p>{{ optionsFrom }}-{{ optionsTo }} of {{ optionsOf }}</p>
      <button @click.prevent="toNextPage">&rsaquo;</button
      ><button @click.prevent="toLastPage">&raquo;</button>
    </div>
  </div>
</template>
<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { watch } from "vue";

export default {
  name: "PaginatedTable",
  data() {
    const total = this.entries.length;
    const cp = 0;
    const npp = 10;
    const pd = this.paginateData(cp, npp, this.entries);

    return {
      shouldShowFooter: this.showFooter(this.footers),
      currentPage: cp,
      numPerPage: npp,
      numPages: this.calcNumPages(total, npp),
      pagedData: pd,
      allNumPerPage: this.calcNumPerPage(total),
      optionsFrom: this.calcFrom(cp, npp),
      optionsTo: this.calcTo(cp, npp, total),
      optionsOf: total,
    };
  },
  mounted() {
    watch(
      () => this.numPerPage,
      () => {
        this.currentPage = 0;
        this.updateAll();
      }
    );
    watch(() => this.currentPage, this.updateAll);
    watch(() => this.entries, this.updateAll);
  },
  methods: {
    internalDelete(entry) {
      const OPERATION = "Deletion operation";

      const reifiedParams = this.deletePromptParams
        .map((param) => entry[param])
        .join(" - ");
      const prompt = `Delete [${reifiedParams}]?`;
      if (confirm(prompt)) {
        this.externalDeleteFunc(entry.id)
          .then((data) => {
            if (data.status) {
              notifySuccess(OPERATION, data.message);
              this.removeEntry(entry.id);
            } else {
              notifyError(OPERATION, data.message);
            }
          })
          .catch((error) => notifyError(OPERATION, error));
      }
    },
    internalEdit(entry) {
      this.externalEditFunc(entry);
    },
    removeEntry(id) {
      const foundAt = this.entries.findIndex((x) => x.id === id);
      if (foundAt !== -1) {
        this.entries.splice(foundAt, 1);
      }
    },
    paginateData(currentPage, numPerPage, allEntries) {
      const startAt = currentPage * numPerPage;
      const endBefore = startAt + numPerPage;
      if (numPerPage === 0) {
        return allEntries;
      } else {
        return allEntries.slice(startAt, endBefore);
      }
    },
    calcNumPages(total, numPerPage) {
      return Math.ceil(total / numPerPage);
    },
    calcNumPerPage(dataLength) {
      const list = [];
      if (dataLength > 10) list.push(10);
      if (dataLength > 50) list.push(50);
      if (dataLength > 100) list.push(100);
      if (dataLength > 500) list.push(500);
      if (dataLength > 1000) list.push(1000);
      return list.concat(Math.ceil((dataLength + 1) / 10) * 10);
    },
    calcFrom(currentPage, numPerPage) {
      return currentPage * numPerPage + 1;
    },
    calcTo(currentPage, numPerPage, total) {
      const calc = currentPage * numPerPage + numPerPage;
      return Math.min(calc, total);
    },
    toFirstPage() {
      this.currentPage = 0;
    },
    toLastPage() {
      this.currentPage = Math.max(this.numPages - 1, 0);
    },
    toPrevPage() {
      this.currentPage = Math.max(this.currentPage - 1, 0);
    },
    toNextPage() {
      if (this.currentPage === this.numPages - 1) return;
      this.currentPage = this.currentPage + 1;
    },
    updateAll() {
      if (this.entries.length === 0) {
        this.currentPage = 0;
        this.numPages = 0;
      } else {
        const cp = this.currentPage;
        const npp = this.numPerPage;
        const data = this.entries;
        this.numPages = this.calcNumPages(data.length, npp);
        this.pagedData = this.paginateData(cp, npp, data);
        this.optionsOf = data.length;
        this.optionsFrom = this.calcFrom(cp, npp);
        this.optionsTo = this.calcTo(cp, npp, data.length);
        this.allNumPerPage = this.calcNumPerPage(data.length);
      }
    },
    showFooter(footerCells) {
      for (const cell of footerCells) {
        if (cell && cell !== "") {
          return true;
        }
      }
      return false;
    },
    calcRowIndex(index) {
      return this.currentPage * this.numPerPage + index;
    },
  },
};
</script>

<style scoped>
.paginated-table-options {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
    line-height: normal;
  }

  button {
    width: 20px;
    height: 20px;
    padding: 0;
  }
}

select {
  width: unset;
  height: unset;
  display: unset;
  padding: unset;
}
</style>
