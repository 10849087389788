<script setup>
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
import PaginatedTable from "@/components/PaginatedTable.vue";
import QueryButtons from "@/components/QueryButtons.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Track Members">
      <fieldset :disabled="IS_POSTING">
        <query-buttons
          :query-func="queryData"
          :reset-func="resetForm"
        ></query-buttons>
      </fieldset>
      <paginated-table
        :can-delete="canDelete"
        :can-edit="canEdit"
        :columns="['name', 'phone', 'email', 'date_created']"
        :entries="entries"
        :footers="['']"
        :headers="['Name', 'Phone', 'Email', 'Date']"
        table-id="members_table"
      >
      </paginated-table>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { FETCHED } from "@/services/PersistenceService";

export default {
  name: "MembersReport",
  data() {
    return {
      canEdit: false,
      canDelete: false,
      entries: [],
    };
  },
  methods: {
    queryData() {
      this.entries = FETCHED.members.map((entry) => {
        return {
          name: entry.name,
          phone: entry.phones.join("\n"),
          email: entry.emails.join("\n"),
        };
      });
    },
    resetForm() {
      this.entries = [];
    },
  },
};
</script>
