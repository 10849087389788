<script setup>
import { IS_POSTING } from "@/services/Caller";
import CsvFileInput from "@/components/treasury/paybill/CsvFileInput.vue";
import { postPaybillReconciliationPayment } from "@/services/PostService";
import PopupForm from "@/components/treasury/paybill/PopupForm.vue";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
import PaginatedTable from "@/components/PaginatedTable.vue";
</script>

<template>
  <div>
    <form class="form">
      <collapsible-wrapper title="M-PESA Statement Reconciliation">
        <fieldset :disabled="IS_POSTING">
          <p>Notice: Remove the summary section</p>
          <label>
            Name
            <CsvFileInput @processed="fileProcessed" />
          </label>
          <div class="inline button-group">
            <button
              class="reset-button"
              type="button"
              @click.prevent="resetForm()"
            >
              Reset
            </button>
            <button
              :disabled="null === data"
              class="submit-button"
              type="button"
              @click.prevent="post()"
            >
              Submit
            </button>
          </div>
        </fieldset>
        <paginated-table
          :can-delete="canDelete"
          :can-edit="canEdit"
          :columns="[
            'receipt_no',
            'completion_time',
            'paid_in',
            'acc_no',
            'other_party_info',
          ]"
          :entries="rows"
          :external-edit-func="editEntry"
          :footers="['', 'Total', total, '', '']"
          :headers="[
            'Transaction ID',
            'Timestamp',
            'Amount',
            'Contribution',
            'Contributor',
          ]"
          table-id="reconciliation_table"
        >
        </paginated-table>
      </collapsible-wrapper>
    </form>

    <popup-form
      :close-popup="closePopup"
      :handle-posted="handlePosted"
      :modal-entry="modalEntry"
      :popup-trigger="popupTrigger"
      :post-function="postPaybillReconciliationPayment"
    ></popup-form>
  </div>
</template>

<script>
import { postPaybillReconciliationBulk } from "@/services/PostService";
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { ref } from "vue";

export default {
  name: "ReconciliationReport",
  data() {
    return {
      canEdit: true,
      canDelete: false,
      inputElement: null,
      data: null,
      rows: [],
      total: 0,
      modalEntry: {},
      popupTrigger: ref(false),
    };
  },
  methods: {
    resetForm() {
      if (this.inputElement) {
        this.inputElement.value = null;
      }
      this.data = null;
      this.rows = [];
      this.total = 0;
      this.modalEntry = {};
    },
    fileProcessed(data) {
      this.data = data;
    },
    post() {
      const OPERATION = "Reconciliation operation";

      if (this.data) {
        postPaybillReconciliationBulk({
          csvString: this.data,
        })
          .then((data) => {
            if (data.status) {
              notifySuccess(OPERATION, data.message);
              this.rows = data.data;
              this.rows.forEach((row) => {
                const parsed = Number.parseInt(row.paid_in);
                if (!Number.isNaN(parsed)) {
                  this.total += parsed;
                }
              });
            } else {
              notifyError(OPERATION, data.message);
            }
          })
          .catch((error) => {
            notifyError(OPERATION, error);
          });
      }
    },
    handlePosted(data) {
      this.removeEntry(data.id);
      this.closePopup();
    },
    openPopup() {
      this.popupTrigger = true;
    },
    closePopup() {
      this.popupTrigger = false;
    },
    editEntry(data) {
      this.modalEntry = {
        id: data.receipt_no,
        contributor: data.other_party_info,
        time: data.completion_time,
        amount: data.paid_in,
        accNo: data.acc_no,
      };
      this.openPopup();
    },
    removeEntry(id) {
      const foundAt = this.rows.findIndex((x) => x.id === id);
      if (foundAt !== -1) {
        this.rows.splice(foundAt, 1);
      }
    },
  },
};
</script>
