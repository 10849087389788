<script setup>
import { FETCHED } from "@/services/PersistenceService";
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
import PaginatedTable from "@/components/PaginatedTable.vue";
import QueryButtons from "@/components/QueryButtons.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Track Expenses">
      <fieldset :disabled="IS_POSTING">
        <div class="filters">
          <label
            >Department
            <select v-model="params.departmentId" name="category">
              <option
                v-for="department in FETCHED.departments"
                :key="department.id"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </label>
          <label
            >Recorded from
            <input
              v-model.trim="params.fromDateCreated"
              name="from"
              type="date"
            />
          </label>
          <label
            >Recorded to
            <input v-model.trim="params.toDateCreated" name="to" type="date" />
          </label>
        </div>
        <query-buttons
          :query-func="queryData"
          :reset-func="resetForm"
        ></query-buttons>
      </fieldset>
      <paginated-table
        :can-delete="canDelete"
        :can-edit="canEdit"
        :columns="[
          'date_disbursed',
          'department',
          'contribution',
          'purpose',
          'dependent_type',
          'posted_by',
          'amount',
          'date_created',
        ]"
        :entries="entries"
        :footers="['', '', '', '', '', 'Total', total, '']"
        :headers="[
          'Disbursed',
          'Department',
          'Category',
          'Purpose',
          'Type',
          'Treasurer',
          'Amount',
          'Recorded',
        ]"
        table-id="expenditure_table"
      >
      </paginated-table>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { fetchExpenditures } from "@/services/FetchService";

export default {
  name: "ExpenditureReport",
  data() {
    return {
      canEdit: false,
      canDelete: false,
      params: {
        departmentId: null,
        source: null,
        creatorId: null,
        fromDateDisbursed: null,
        toDateDisbursed: null,
        fromDateCreated: null,
        toDateCreated: null,
      },
      entries: [],
      total: null,
    };
  },
  methods: {
    queryData() {
      const params = {
        status: 1,
      };
      if (this.params.departmentId) {
        params.department_id = this.params.departmentId;
      }
      if (this.params.fromDateCreated) {
        params.from = this.params.fromDateCreated;
      }
      if (this.params.toDateCreated) {
        params.to = this.params.toDateCreated;
      }
      fetchExpenditures(params)
        .then((x) => {
          notifySuccess("Fetch operation", "Expenses have been fetched");

          const currencyFormatter = new Intl.NumberFormat("en-KE", {
            style: "currency",
            currency: "KES",
          });
          const sum = x.reduce((result, entry) => result + entry.amount, 0);
          this.entries = x.map((entry) => {
            entry.amount = currencyFormatter.format(entry.amount);
            entry.source = entry.source === "D" ? "Church" : "Self";
            return entry;
          });
          this.total = currencyFormatter.format(sum);
        })
        .catch((error) => notifyError("Fetch operation", error));
    },
    resetForm() {
      this.entries = [];
      this.params = {
        departmentId: null,
        source: null,
        creatorId: null,
        fromDateDisbursed: null,
        toDateDisbursed: null,
        fromDateCreated: null,
        toDateCreated: null,
      };
    },
  },
};
</script>
