<script setup>
import { IS_POSTING } from "@/services/Caller";
import { postPaybillValidations } from "@/services/PostService";
import PopupForm from "@/components/treasury/paybill/PopupForm.vue";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
import PaginatedTable from "@/components/PaginatedTable.vue";
import QueryButtons from "@/components/QueryButtons.vue";
</script>

<template>
  <div>
    <form class="form">
      <collapsible-wrapper title="Track Pending Paybill Transactions">
        <fieldset :disabled="IS_POSTING">
          <query-buttons
            :query-func="queryData"
            :reset-func="resetForm"
          ></query-buttons>
        </fieldset>
        <paginated-table
          :can-delete="canDelete"
          :can-edit="canEdit"
          :columns="['id', 'phone', 'amount', 'time', 'acc_no']"
          :entries="entries"
          :external-edit-func="editEntry"
          :footers="['', 'Total', total, '', '']"
          :headers="[
            'Transaction ID',
            'Phone number',
            'Amount',
            'Transaction time',
            'Description',
          ]"
          table-id="pending_transactions_table"
        >
        </paginated-table>
      </collapsible-wrapper>
    </form>

    <popup-form
      :close-popup="closePopup"
      :handle-posted="handlePosted"
      :modal-entry="modalEntry"
      :popup-trigger="popupTrigger"
      :post-function="postPaybillValidations"
    ></popup-form>
  </div>
</template>

<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { fetchPendingPaybillValidations } from "@/services/FetchService";
import { isEditingTreasurer } from "@/services/AuthService";
import { ref } from "vue";

export default {
  name: "PendingTransactionsReport",
  data() {
    return {
      canEdit: isEditingTreasurer(),
      canDelete: false,
      entries: [],
      total: 0,
      currencyFormatter: new Intl.NumberFormat("en-KE", {
        style: "currency",
        currency: "KES",
      }),
      modalEntry: {},
      popupTrigger: ref(false),
      timestampFormatter: (timestamp) => new Date(timestamp).toLocaleString(),
    };
  },
  methods: {
    queryData() {
      fetchPendingPaybillValidations({})
        .then((x) => {
          notifySuccess("Fetch operation", x.message);

          const sum = x.reduce((result, entry) => result + entry.amount, 0);
          this.entries = x.map((entry) => {
            entry.amount = this.currencyFormatter.format(entry.amount);
            entry.time = this.timestampFormatter(entry.time);
            return entry;
          });
          this.total = this.currencyFormatter.format(sum);
        })
        .catch((error) => notifyError("Fetch operation", error));
    },
    resetForm() {
      this.closePopup();
      this.entries = [];
      this.total = 0;
      this.modalEntry = {};
    },
    removeEntry(id) {
      const foundAt = this.entries.findIndex((x) => x.id === id);
      if (foundAt !== -1) {
        this.entries.splice(foundAt, 1);
      }
    },
    handlePosted(data) {
      this.removeEntry(data.id);
      this.closePopup();
    },
    openPopup() {
      this.popupTrigger = true;
    },
    closePopup() {
      this.popupTrigger = false;
    },
    editEntry(data) {
      this.modalEntry = {
        id: data.id,
        contributor: data.phone,
        time: data.time,
        amount: data.amount,
        accNo: data.acc_no,
      };
      this.openPopup();
    },
  },
};
</script>
