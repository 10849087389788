<script setup>
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
import PaginatedTable from "@/components/PaginatedTable.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Track Categories">
      <fieldset :disabled="IS_POSTING"></fieldset>
      <paginated-table
        :can-delete="canDelete"
        :can-edit="canEdit"
        :columns="['name', 'department']"
        :entries="entries"
        :footers="['', '']"
        :headers="['Category', 'Department']"
        table-id="categories_table"
      >
      </paginated-table>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { FETCHED } from "@/services/PersistenceService";

export default {
  name: "CategoriesReport",
  data() {
    return {
      canEdit: false,
      canDelete: false,
      entries: FETCHED.categories,
    };
  },
};
</script>
