<script setup>
import { IS_POSTING } from "@/services/Caller";
import CollapsibleWrapper from "@/components/CollapsibleWrapper.vue";
import PaginatedTable from "@/components/PaginatedTable.vue";
</script>

<template>
  <form class="form">
    <collapsible-wrapper title="Track Departments">
      <fieldset :disabled="IS_POSTING"></fieldset>
      <paginated-table
        :can-delete="canDelete"
        :can-edit="canEdit"
        :columns="['name']"
        :entries="entries"
        :footers="['']"
        :headers="['Department']"
        table-id="departments_table"
      >
      </paginated-table>
    </collapsible-wrapper>
  </form>
</template>

<script>
import { FETCHED } from "@/services/PersistenceService";

export default {
  name: "DepartmentsReport",
  data() {
    return {
      canEdit: false,
      canDelete: false,
      entries: FETCHED.departments,
    };
  },
};
</script>
